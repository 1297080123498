import React, { Suspense, useRef, useLayoutEffect, useEffect } from 'react';
import { Link } from 'gatsby';
import { useControls, Leva } from 'leva';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import Layout from '../components/Layout';

import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { useProgress, Environment, Stars } from '@react-three/drei';

import IAMModel from '../components/IAMAnimatedD.js';
import iam from '../img/i-am.svg';
import iconMouse from '../img/iconmouse.svg';
import iconArrowGold from '../img/iconarrow-gold.svg';

// TODO: Movement Regression

// docs.pmnd.rs/react-three-fiber/advanced/scaling-performance
// https://codesandbox.io/s/performance-scaling-pz0q6?from-embed=&file=/src/App.js:463-470

// eslint-disable-next-line
export const IndexPageTemplate = ({}) => {
  const homeRef = useRef(0);
  let windowChecked = typeof window !== `undefined` ? window : null;

  // DEBUG PANEL

  const { cameraFOV, cameraAngleX, cameraAngleY, cameraLerp } = useControls('camera', {
    cameraFOV: { value: 32, min: 0, max: 100, step: 1 },
    cameraAngleX: { value: 0.3, min: 0, max: 5, step: 0.01 },
    cameraAngleY: { value: 0.3, min: 0, max: 5, step: 0.01 },
    cameraLerp: { value: 0.075, min: 0.001, max: 0.5, step: 0.001 },
  });

  const { particleRadius, particleDepth, particleFactor, particleCount } = useControls('Particles', {
    particleFactor: { value: 0.13, min: 0, max: 2, step: 0.01 },
    particleCount: { value: 90, min: 0, max: 1000, step: 1 },
    particleDepth: { value: 0.95, min: 0, max: 3, step: 0.01 },
    particleRadius: { value: 0.1, min: 0, max: 3, step: 0.1 },
  });

  const { envMap, envMapIntensity, metalnessVenus, metalnessHeart, metalnessStone, roughnessStone, roughnessVenus, roughnessHeart } = useControls('Materials', {
    envMap: { value: 0, min: 0, max: 3, step: 1 },
    envMapIntensity: { value: 2, min: 0, max: 10, step: 0.01 },
    metalnessStone: { value: 0.5, min: 0, max: 1, step: 0.01 },
    roughnessStone: { value: 1, min: 0, max: 1, step: 0.01 },
    metalnessVenus: { value: 0.5, min: 0, max: 1, step: 0.01 },
    roughnessVenus: { value: 1, min: 0, max: 1, step: 0.01 },
    metalnessHeart: { value: 0.14, min: 0, max: 1, step: 0.01 },
    roughnessHeart: { value: 1, min: 0, max: 1, step: 0.01 },
  });

  const debugObj = {
    envMapIntensity,
    metalnessStone,
    metalnessVenus,
    metalnessHeart,
    roughnessStone,
    roughnessVenus,
    roughnessHeart,
  };

  const { spotLightColor, spotLightPosition, spotLightIntensity, spotLightDistance, spotLightAngle, spotLightPenumbra } = useControls('spotLight', {
    spotLightColor: '#FF82db',
    spotLightPosition: [-7, 2, -1.5],
    spotLightIntensity: { value: 20, min: 0, max: 50, step: 0.1 },
    spotLightDistance: { value: 50, min: 0, max: 100, step: 0.1 },
    spotLightAngle: { value: 2, min: 0, max: 3, step: 0.01 },
    spotLightPenumbra: { value: 1.42, min: 0, max: 3, step: 0.01 },
  });

  const { spotLight1Color, spotLight1Position, spotLight1Intensity, spotLight1Distance, spotLight1Angle, spotLight1Penumbra } = useControls('spotLight1', {
    spotLight1Color: '#5873fc',
    spotLight1Position: [10, 5, 5],
    spotLight1Intensity: { value: 13.6, min: 0, max: 50, step: 0.1 },
    spotLight1Distance: { value: 50, min: 0, max: 100, step: 0.1 },
    spotLight1Angle: { value: 3, min: 0, max: 3, step: 0.01 },
    spotLight1Penumbra: { value: 1, min: 0, max: 3, step: 0.01 },
  });

  //Spotlight with Helper

  const spotLightRef = useRef(0);
  const spotLight1Ref = useRef(0);

  function SpotlightH({ ...props }) {
    const { passref, position, intensity, distance, penumbra, angle, color } = props;

    // useHelper(passref, SpotLightHelper, color);

    return (
      <spotLight
        ref={passref}
        position={position}
        intensity={intensity}
        distance={distance}
        penumbra={penumbra}
        angle={angle}
        color={color}
        // castShadow
        // shadow-bias={-0.01}
        // shadow-mapSize-height={512 + 256}
        // shadow-mapSize-width={512 + 256}
      />
    );
  }

  // Loading Progress
  // Progressive Loader - lowpoly first https://codesandbox.io/s/progressive-loading-states-with-suspense-7duy8

  function Loader() {
    const { progress } = useProgress();

    const normalProgress = progress / 100;

    return (
      <mesh position={[0, 0, 0]}>
        <ringBufferGeometry args={[0.125, 1.618 * 0.125, 32, 64, 0, Math.PI * 2 * normalProgress]} />
        <meshBasicMaterial color="#505050" />
      </mesh>
    );
  }
  // Device Orientation Hanlder

  // const [deviceMotion, setDeviceMotion] = useState({
  //   // alpha: 0,
  //   beta: 0,
  //   gamma: 0,
  // });

  const motionref = useRef({ alpha: 0, beta: 0, gamma: 0 });

  function handleDeviceMotion(event) {
    motionref.current = {
      alpha: event.rotationRate.alpha / 750,
      beta: event.rotationRate.beta / 750,
      // gamma: event.rotationRate.gamma / 200,
    };
  }

  useEffect(() => {
    windowChecked.addEventListener('devicemotion', handleDeviceMotion);

    return () => {
      windowChecked.removeEventListener('devicemotion', handleDeviceMotion);
    };
  }, [windowChecked]);

  // Camera Mouse Follow Animation
  function CameraRig() {
    return useFrame((state) => {
      // console.log(state.clock.elapsedTime);
      state.scene.rotation.x = THREE.MathUtils.lerp(state.scene.rotation.x, state.mouse.y * -cameraAngleX + motionref.current.alpha + Math.cos(state.clock.elapsedTime) * 0.025, cameraLerp);
      state.scene.rotation.y = THREE.MathUtils.lerp(state.scene.rotation.y, state.mouse.x * cameraAngleY + motionref.current.beta + Math.sin(state.clock.elapsedTime) * 0.025, cameraLerp);

      state.camera.lookAt(0, 0, 0.5);

      state.camera.fov = cameraFOV;
      state.camera.updateProjectionMatrix();

      // Digital Section - Move Spotlights with Mouse

      state.scene.children[0].intensity = GSAPObj.intensity;
      state.scene.children[1].intensity = GSAPObj.intensity;

      state.scene.children[0].position.y = THREE.MathUtils.lerp(state.scene.children[0].position.y, state.mouse.y * 5, 0.01);
      state.scene.children[1].position.y = THREE.MathUtils.lerp(state.scene.children[1].position.y, state.mouse.x * -5, 0.01);

      state.scene.children[3].traverse(function (obj) {
        if (obj.material) {
          obj.material.metalness = GSAPObj.metalness;
        }
      });
    });
  }

  // GSAP
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  // Timelines
  const scrollProgressTL = useRef();
  const scrollIndicatorTL = useRef();
  const lightTl = useRef();
  const materialTl = useRef();
  const bgTl = useRef();
  const textFadeTl = useRef();

  const digitalLottieRef = useRef();

  const isBrowser = typeof window !== 'undefined';

  const GSAPObj = {
    intensity: spotLightIntensity,
    metalness: metalnessVenus,
  };

  // Resets Scroll Position to top - GSAP and Animation Timeline are off
  useEffect(() => {
    if (isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  });

  // GSAP INIT
  useLayoutEffect(() => {
    function init() {
      const q = gsap.utils.selector(homeRef.current);

      // SCROLL WITH SCROLLSNAP

      let sections = q('.home-section');
      // windowInnerHeight =
      //   typeof window !== `undefined` ? window.innerHeight : null;

      let canvas = q('#canvas-container');
      let scrollBar = q('.scroll-progress-bar');
      let scrollBarContainer = q('.scroll-progress-bar-container');
      let scrollIndicator = q('.scroll-indicator');
      let sectionDIGITAL = sections[2];

      // HOME - Scroll Progress

      scrollProgressTL.current = gsap
        .timeline({
          scrollTrigger: {
            scrub: 0.3,
          },
        })
        .to(scrollBar, {
          ease: 'none',
          width: 6,
        })
        .to(scrollBarContainer, {
          ease: 'none',
          y: 16,
        })
        .to(scrollBar, {
          ease: 'none',
          width: 60,
        })
        .to(scrollBar, {
          ease: 'none',
          width: 6,
        })
        .to(scrollBarContainer, {
          ease: 'none',
          y: 32,
        })
        .to(scrollBar, {
          ease: 'none',
          width: 60,
        })
        .to(scrollBar, {
          ease: 'none',
          width: 6,
        })
        .to(scrollBarContainer, {
          ease: 'none',
          y: 48,
        })
        .to(scrollBar, {
          ease: 'none',
          width: 60,
        });

      // HOME - Scroll Indicator

      scrollIndicatorTL.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: sections[0],
            scrub: 0.3,
            start: 'top top',
          },
        })
        .to(scrollIndicator, {
          ease: 'expo',
          opacity: 0,
        });

      // I AM DIGITAL - Turns on Lights

      lightTl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionDIGITAL,
            start: 'center bottom',
            end: 'center top',
            // markers: true,
            scrub: true,
            onEnter: (self) => {
              if (digitalLottieRef.current) {
                digitalLottieRef.current.setSpeed(1.5);
                digitalLottieRef.current.goToAndPlay(0, false);
              }
            },
            onEnterBack: (self) => {
              if (digitalLottieRef.current) {
                digitalLottieRef.current.setSpeed(1.5);
                digitalLottieRef.current.goToAndPlay(0, false);
              }
            },
          },
        })
        .from(GSAPObj, {
          intensity: 0,
          ease: 'expo',
        })
        .to(GSAPObj, {
          intensity: 0,
          ease: 'expo',
        });

      // I AM DIGITAL - Changes Material
      materialTl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionDIGITAL,
            start: 'center bottom',
            end: 'center top',
            // markers: true,
            scrub: true,
          },
        })
        .to(GSAPObj, {
          metalness: 1,
          ease: 'expo',
        })
        .to(GSAPObj, {
          metalness: 0.5,
          ease: 'expo',
        });

      // I AM DIGITAL- Change Background
      bgTl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionDIGITAL,
            start: 'center bottom',
            end: 'center top',
            // markers: true,
            scrub: true,
          },
        })
        .to(canvas, {
          background: 'radial-gradient(circle, #2f2135 0%, #1c1623 50%)',
          ease: 'expo',
        })
        .to(canvas, {
          background: 'radial-gradient(circle, #393939 0%, #101010 50%)',
          ease: 'expo',
        });

      gsap.set(sections, { x: 0 });

      // add a label for each section to the timeline (for "labelsDirectional" functionality):

      sections.forEach((section, i) => {
        // tl.add("label" + (labelNumber += 1), section.offsetTop);

        let qs = gsap.utils.selector(section);
        let sectionLink = qs('.iam-link');

        // Adds Scroll Snapping and Textfade

        textFadeTl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: section,
              // pin: true,
              // pinSpacing: false,
              scrub: 0.3,
              // snap: {
              //   snapTo: true, // snap to the closest label in the timeline
              //   duration: { min: 0.1, max: 0.25 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
              //   // delay: , // wait 0.2 seconds from the last scroll event before doing the snapping
              //   ease: 'none', // the ease of the snap animation ("power3" by default)
              //   inertia: false,
              // },
            },
          })
          .from(section, {
            ease: 'expo',
            opacity: 0,
          })

          .from(sectionLink, {
            ease: 'expo',
            opacity: 0,
            xPercent: 10,
          })
          .to(sectionLink, {
            ease: 'expo',
            opacity: 1,
            xPercent: 0,
          });
      });
    }

    // windowChecked.addEventListener('devicemotion', handleDeviceMotion);

    init();

    // ScrollTrigger.addEventListener("refreshInit", init); // on resize, things must be recalculated
    ScrollTrigger.refresh();
    // ScrollTrigger.addEventListener("refresh", init); // on resize, things must be recalculated

    return () => {
      let triggers = ScrollTrigger.getAll();
      triggers.forEach((trigger) => {
        trigger.kill();
      });
      // windowChecked.removeEventListener('devicemotion', handleDeviceMotion);
      // ScrollTrigger.removeEventListener("refreshInit", init);
      // ScrollTrigger.removeEventListener("refresh", init);
    };
  });

  return (
    <main ref={homeRef}>
      {/* Debug Panel Config */}
      <Leva
        collapsed
        oneLineLabels
        hidden // default = false, when true the GUI is hidden
      />
      {/* Page Content */}
      <div className="home-main">
        {/* I AM STUDIO */}
        <section className="home-section">
          <div className="section-container">
            {/* <img
              className="section-title"
              src={studio}
              alt="I AM STUDIO"
              style={{ height: '10vw' }}
            /> */}
            <h2 className="section-title-font">STUDIO</h2>
            <Link className="iam-link" to="/about">
              <img className="iam-link-arrow" src={iconArrowGold} alt="I AM" style={{ width: '20px' }} />
              <p>ABOUT US</p>
            </Link>
          </div>
        </section>

        {/* I AM CREATIVE */}
        <section className="home-section">
          <div className="section-container">
            {/* <img
              className="section-title"
              src={creative}
              alt="I AM CREATIVE"
              style={{ height: '10vw' }}
            /> */}
            <h2 className="section-title-font">CREATIVE</h2>
            <Link className="iam-link" to="/creative">
              <img className="iam-link-arrow" src={iconArrowGold} alt="I AM" style={{ width: '20px' }} />
              <p>SEE PORTFOLIO</p>
            </Link>
          </div>
        </section>

        {/* I AM DIGITAL */}
        <section className="home-section">
          <div className="section-container">
            {/* <img
              className="section-title"
              src={digital}
              alt="I AM DIGITAL"
              style={{ height: '10vw' }}
            /> */}
            <h2 className="section-title-font">DIGITAL</h2>
            <Link className="iam-link" to="/digital">
              <img className="iam-link-arrow" src={iconArrowGold} alt="I AM" style={{ width: '20px' }} />
              <p>SEE PORTFOLIO</p>
            </Link>
          </div>
        </section>

        {/* I AM HERE */}
        <section className="home-section">
          <div className="section-container">
            {/* <img
              className="section-title"
              src={here}
              alt="I AM HERE"
              style={{ height: '10vw' }}
            /> */}
            <h2 className="section-title-font">HERE</h2>
            <Link className="iam-link" to="/contact">
              <img className="iam-link-arrow" src={iconArrowGold} alt="I AM" style={{ width: '20px' }} />
              <p>CONTACT US</p>
            </Link>
          </div>
        </section>
        <div className="grain"></div>
        <div className="home-overlay">
          <div className="home-overlay-container">
            <div className="home-iamquote">
              <p>WHAT I AM</p>
              <p className="offset">IS WHO WE ARE</p>
            </div>
            <img id="home-iam" src={iam} alt="I AM" />
            <div className="scroll-progress">
              <div className="scroll-progress-dot"></div>
              <div className="scroll-progress-dot"></div>
              <div className="scroll-progress-dot"></div>
              <div className="scroll-progress-dot"></div>
              <div className="scroll-progress-bar-container">
                <div className="scroll-progress-bar" />
              </div>
            </div>
            <img className="scroll-indicator" src={iconMouse} alt="I AM" style={{ width: '20px' }} />
            <p className="copyright">©2022 all right reserved</p>
            <Link className="home-impressum" to="/impressum">
              <p>IMPRESSUM</p>
            </Link>
          </div>
        </div>
      </div>

      <div id="canvas-container">
        {/* <Canvas  dpr={[1, 2]}> */}
        <Canvas
          mode="concurrent"
          dpr={[0.1, 2]}
          // frameloop="demand"
          // shadows
          // linear
          // sRGB
          colorManagement
          camera={{ fov: 32 }}
          gl={{
            // Good for batter bad performance
            // powerPreference: "low-power",
            // Good performance bad battery
            powerPreference: 'high-performance',
            // alpha: false,
            // antialias: false,
            stencil: false,
            // depth: false,
          }}
        >
          {/* <color attach="background" args={[0x101010]} /> */}
          <fog attach="fog" args={['#101010', 10, 20]} />
          <SpotlightH
            passref={spotLightRef}
            position={spotLightPosition}
            intensity={spotLightIntensity}
            distance={spotLightDistance}
            penumbra={spotLightPenumbra}
            angle={spotLightAngle}
            color={spotLightColor}
          />
          <SpotlightH
            passref={spotLight1Ref}
            position={spotLight1Position}
            intensity={spotLight1Intensity}
            distance={spotLight1Distance}
            penumbra={spotLight1Penumbra}
            angle={spotLight1Angle}
            color={spotLight1Color}
          />
          <Stars radius={particleRadius} depth={particleDepth} count={particleCount} factor={particleFactor} saturation={0.5} fade />
          {/* LOAD IAM MODEL */}
          <Suspense fallback={<Loader />}>
            <IAMModel position={[0, 0, 0]} scale={[0.8, 0.8, 0.8]} debugObj={debugObj} gsapObj={GSAPObj} />
            <Environment
              background={false}
              files={['/HDRI_right_1.hdr', '/HDRI_All_1.hdr', '/HDRI_left_1.hdr', '/HDRI_center_1.hdr'][envMap]}
              path="/environment"

              // presets https://github.com/pmndrs/drei/blob/master/src/helpers/environment-assets.ts
              // preset={"forest"}
            />
          </Suspense>
          <CameraRig />
          {/* ORBIT CONTROLS */}
          {/* <OrbitControls
            enablePan={true}
            minPolarAngle={Math.PI / 3}
            maxPolarAngle={Math.PI / 1.5}
            enableZoom={true}
            minDistance={1}
            maxDistance={20}
            zoomSpeed={0.25}
            enableRotate={true}
            // minAzimuthAngle={Math.PI / -3}
            // maxAzimuthAngle={Math.PI / 3}
            rotateSpeed={0.25}
          /> */}
        </Canvas>
      </div>
    </main>
  );
};

const IndexPage = ({ data }) => {
  // const { frontmatter } = data?.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate
      // title={frontmatter.title}
      // heading={frontmatter.heading}
      // subheading={frontmatter.subheading}
      />
    </Layout>
  );
};

export default IndexPage;

// export const pageQuery = graphql`
//   query IndexPageTemplate {
//     markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
//       frontmatter {
//         title
//         heading
//         subheading
//       }
//     }
//   }
// `;
