/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useLayoutEffect } from 'react';
import * as THREE from 'three';
// import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

export default function Model({ ...props }) {
  const { nodes, materials, animations, scene } = useGLTF(
    '/models/IAMAnimatedD13052022.gltf',
    '/draco/'
  );
  const { actions } = useAnimations(animations, scene);
  const isBrowser = typeof window !== 'undefined';
  const scrollY = useRef(0);

  let {
    // alphaMapYrepeat,
    metalnessVenus,
    metalnessHeart,
    metalnessStone,
    roughnessVenus,
    roughnessHeart,
    roughnessStone,
    envMapIntensity,
  } = props.debugObj;

  // const alphaMap = useLoader(TextureLoader, "/models/alphaMap.png");
  // materials["Mat_Venus"].map = null;
  // materials["Mat_Venus"].alphaMap = alphaMap;
  // materials["Mat_Venus"].transparent = true;
  // materials["Mat_Venus"].side = THREE.DoubleSide;
  // materials["Mat_Venus"].alphaTest = 0.5;
  // materials["Mat_Venus"].alphaMap.magFilter = THREE.NearestFilter;
  // materials["Mat_Venus"].alphaMap.wrapT = THREE.RepeatWrapping;

  // console.log(materials, "materials");
  // console.log(scene, "scene");

  useLayoutEffect(() =>
    Object.values(nodes).forEach(
      (node) => (node.receiveShadow = node.castShadow = true)
    )
  );

  useEffect(() => {
    // Starts Animation and puts it on pause
    actions['Animation'].play().paused = true;
  }, [actions]);

  useEffect(() => {
    // materials["Mat_Venus"].wireframe = true;
    // Metalness
    materials['Mat_Stone'].metalness = metalnessStone;
    materials['Mat_Venus'].metalness = metalnessVenus;
    materials['Mat_heart'].metalness = metalnessHeart;
    // Roughness
    materials['Mat_Stone'].roughness = roughnessStone;
    materials['Mat_Venus'].roughness = roughnessVenus;
    materials['Mat_heart'].roughness = roughnessHeart;
    // EnvMap Intenty
    materials['Mat_Stone'].envMapIntensity = envMapIntensity;
    materials['Mat_Venus'].envMapIntensity = envMapIntensity;
    materials['Mat_heart'].envMapIntensity = envMapIntensity;
  });

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', scrollYNormalized);
    }

    return () => {
      if (isBrowser) {
        window.removeEventListener('scroll', scrollYNormalized);
      }
    };
  });

  function scrollYNormalized() {
    if (isBrowser) {
      scrollY.current =
        document.documentElement.scrollTop /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight);
    }
  }

  useFrame((state, delta) => {
    const action = actions['Animation'];

    // The offset is between 0 and 1, you can apply it to your models any way you like

    // action.time = action.getClip().duration * scrollY.current;
    action.time = THREE.MathUtils.damp(
      action.time,
      action.getClip().duration * scrollY.current,
      3,
      delta
    );
  });
  return <primitive object={scene} {...props} />;
}

useGLTF.preload('/models/IAMAnimatedD.gltf');
